import {LoadableComponent} from "@loadable/component";
import {BackgroundFilter} from "@workhorse/api/conference2/lib/VideoReplacement";
import {SessionRole} from "../user";
import React from "@workhorse/api/rendering";
import {CollectFeedbackType, MemoryAccessType, SessionFlagsType, SessionLifecycle} from "@generated/data";

/**
 * @description The list of the implemented Session Groups. When adding a new group, add it's key here
 */
export type SessionSettingsGroupMap = "device" | "general";

/**
 * @description The list of implemented sections for each individual group
 */
export type SessionSettingsSections<TGroup = SessionSettingsGroup> = TGroup extends "device"
    ? "video" | "audio" | "backgroundEffects" | "notifications"
    : TGroup extends "general"
    ? "session" | "widgets" | "permissions" | "automation" | "recording" | "livestreaming" | "exitPage"
    : never;

/**
 * @description A type guard to determine if the `groupOrSection` is a group or a section
 * @param groupOrSection
 */
export const isSessionSection = (
    groupOrSection: SessionSettingsGroupMap | SessionSettingsSelection
): groupOrSection is SessionSettingsSelection => groupOrSection.includes(".");

/**
 * @description This selection contains each individual section prefixed by the group it belongs to
 */
export type SessionSettingsSelection<TGroup = SessionSettingsGroupMap> = TGroup extends SessionSettingsGroupMap
    ? `${TGroup}.${SessionSettingsSections<TGroup>}`
    : never;

/**
 * @description Details the entire states of each individual section - useful for cross-section communicationc
 */
export type SessionSettingsSectionState<TSection = SessionSettingsSelection> = Partial<
    TSection extends "device.video"
        ? {
              deviceId: string;
              cameraEnabled: boolean;
              conferenceCameraEnabled: boolean;
              conferenceMicrophoneEnabled: boolean;
              cameraMirrorSelfView: boolean;
              cameraHidden: boolean;
          }
        : TSection extends "device.audio"
        ? {
              inputDeviceId: string;
              outputDeviceId: string;
              microphoneEnabled: boolean;
              noiseReduction: boolean;
          }
        : TSection extends "device.backgroundEffects"
        ? {
              filter: BackgroundFilter;
          }
        : TSection extends "general.session"
        ? {
              lobbyAccess: boolean;
              showAgendaInLobby: boolean;
              allowCollaborationInLobby: boolean;
              requestPermissionToJoin: boolean;
              requireConsentToRecord: boolean;
              enableReactionsType: SessionFlagsType;
              requestGuestEmail: boolean;
              allowParticipantsShareScreen: boolean;
              allowParticipantsVideo: boolean;
              allowParticipantsAudio: boolean;
              hiddenMacros: string[];
          }
        : TSection extends "general.permissions"
        ? {
              lockedSession: boolean;
              memoryAccessType: MemoryAccessType;
              allowAgendaType: SessionFlagsType;
          }
        : TSection extends "device.notification"
        ? {
              chat: boolean;
              qa: boolean;
              participantsJoined: boolean;
              reactions: boolean;
          }
        : TSection extends "general.automation"
        ? {
              sessionLifecycle: SessionLifecycle;
          }
        : TSection extends "general.recording"
        ? {}
        : TSection extends "general.livestreaming"
        ? {}
        : TSection extends "general.exitPage"
        ? {
              collectFeedback: CollectFeedbackType.SessionsFeedback;
          }
        : never
>;

/**
 * @description The global state of the entire session settings form
 */
export type SessionSettingsGlobalState<TSelection = SessionSettingsSelection> = Partial<
    TSelection extends infer Selection
        ? {
              [TKey in SessionSettingsSelection]: SessionSettingsSectionState<TKey>;
          }
        : never
>;

/**
 * @description This prop type has to be used by all sections
 */
export type SessionSettingsSectionProps<TSection extends SessionSettingsSelection = SessionSettingsSelection> =
    TSection extends infer Section
        ? JSX.IntrinsicElements["div"] & {
              onValidate: (message?: string) => void;
              onTouch: (touched: boolean) => void;

              onSubmitDone: () => void;
              onSettingsStateUpdate: (update: SessionSettingsSectionState<TSection>) => void;

              globalState: SessionSettingsGlobalState<SessionSettingsSelection>;
              submitting: boolean;
              touched: boolean;

              error?: string;
              onGoToRecordingSection?: () => void;
          }
        : never;

/**
 * @description The definition of a Session Settings group
 */
export type SessionSettingsGroup<TGroup = SessionSettingsGroupMap> = TGroup extends infer Group
    ? {
          translateKey: string;

          name: string;

          /**
           * @description The group notice. If none present, and the selected group section has no notice, no notice is displayed.
           */
          notice?: string;

          noticeTranslateKey?: string;

          /**
           * @default "none"
           */
          rbac?: Record<SessionRole | "isOwner" | "isUser" | "isGuest", boolean> | "none";

          /**
           * @description A record of sections for this group
           */
          sections: {
              [key in SessionSettingsSections<Group>]: SessionSettingsSection;
          };
      }
    : never;

/**
 * @description The definition of a Session Settings section
 */
export type SessionSettingsSection<TSection extends SessionSettingsSelection = SessionSettingsSelection> = TSection extends infer Section
    ? {
          /**
           * @description Key for internalization
           */
          translateKey: string;
          /**
           * @description The section kind
           */
          kind: Section;

          /**
           * @description The section title
           */
          title: string;

          /**
           * @description The section notice
           * @default The group notice
           */
          notice?: string;

          noticeTranslateKey?: string;

          /**
           * @default true
           */
          show?: boolean;

          /**
           * @default "none"
           */
          rbac?: Record<SessionRole | "isOwner" | "isUser" | "isGuest", boolean> | "none";

          /**
           * @description The section icon
           */
          icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;

          /**
           * @description a `LoadableComponent` that respects uses the `SessionSettingsSectionProps` type as a prop type
           */
          component: LoadableComponent<SessionSettingsSectionProps>;

          /**
           * @description Used in order to re-render other sections when this section the global state is updated
           *
           * @returns true - don't update
           * @returns false - update
           */
          settingsStateCheck?: (pv: SessionSettingsGlobalState, cv: SessionSettingsGlobalState) => boolean;

          showIfStarted?: (lifecycle: SessionLifecycle) => boolean;
      }
    : never;

/**
 * @description The SessionSettings Configuration type
 */
export type SessionSettingsConfiguration<TGroup extends string = SessionSettingsGroupMap> = TGroup extends SessionSettingsGroupMap
    ? {
          [key in TGroup]: SessionSettingsGroup<TGroup>;
      }
    : never;
