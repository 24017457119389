import {useQuery} from "@apollo/client";
import {
    GetSessionSettingsDocument,
    MemoryAccessType,
    SessionFlagsType,
    SessionSettingsSource,
    SessionSettingsFragmentFragment,
    PrivateChatAccess,
    ParticipantsMacroAccess,
    CollectFeedbackType,
    ExitPageCtaType,
    SessionRecordingType,
} from "@generated/data";
import apollo from "@workhorse/api/apollo";
import {generateSessionPasscode} from "@workhorse/api/session-settings/sections/SessionSection/components/SessionRequestPasscode";
import {readRemoteUser} from "@workhorse/api/user";

export const getSessionTemplateFlags = (
    session: Omit<SessionSettingsFragmentFragment, "id" | "sessionSource" | "__typename">,
    sessionSource: SessionSettingsSource
) => {
    return <SessionSettingsFragmentFragment>{
        sessionSource: sessionSource,
        requestPermissionToJoin: session?.requestPermissionToJoin,
        requireConsentToRecord: session?.requireConsentToRecord,
        enableReactionsType: session?.enableReactionsType,
        askForGuestEmail: session?.askForGuestEmail,
        allowCameraType: session?.allowCameraType,
        allowMicrophoneType: session?.allowMicrophoneType,
        allowScreenShareType: session?.allowScreenShareType,
        hideNonStreamingParticipants: session?.hideNonStreamingParticipants,
        autoRecording: session?.autoRecording,
        memoryAccessType: session?.memoryAccessType,
        allowAgendaType: session?.allowAgendaType,
        reminders: session?.reminders ?? [],
        messageReminders: session?.messageReminders ?? [],
        hiddenMacros: session?.hiddenMacros,
        autoTranscribing: session?.autoTranscribing,
        disabledNotifications: session?.disabledNotifications,
        autoStartSession: session?.autoStartSession,
        sendEndSessionEmail: session?.sendEndSessionEmail,
        fullSizeVideosInRecording: session?.fullSizeVideosInRecording,
        recordingConferenceView: session?.recordingConferenceView,
        recordingPresentationView: session?.recordingPresentationView,
        passcodeToJoin: session?.passcodeToJoin,
        provideSessionPasscode: session?.provideSessionPasscode,
        bypassSessionPasscode: session?.bypassSessionPasscode,
        groupChatAccess: session?.groupChatAccess,
        privateChatAccess: session.privateChatAccess,
        participantsMacroAccess: session.participantsMacroAccess,
        exitPageOfferId: session?.exitPageOfferId,
        collectFeedback: session?.collectFeedback,
        showExitPageCta: session?.showExitPageCta,
        hideIcsGuestList: session?.hideIcsGuestList,
        livestreamOutputUrls: session?.livestreamOutputUrls ?? [],
        recordingType: session?.recordingType,
        feedbackFormId: session?.feedbackFormId,
    };
};

export const initialSessionFlags: {
    [key in SessionSettingsSource]: Omit<SessionSettingsFragmentFragment, "id" | "sessionSource" | "__typename">;
} = {
    [SessionSettingsSource.Instant]: {
        allowMicrophoneType: SessionFlagsType.Everyone,
        allowScreenShareType: SessionFlagsType.Everyone,
        enableReactionsType: SessionFlagsType.Everyone,
        askForGuestEmail: false,
        requestPermissionToJoin: false,
        requireConsentToRecord: false,
        allowCameraType: SessionFlagsType.Everyone,
        hideNonStreamingParticipants: false,
        autoRecording: false,
        memoryAccessType: MemoryAccessType.JustAttendees,
        allowAgendaType: SessionFlagsType.Everyone,
        reminders: {},
        messageReminders: {},
        autoTranscribing: false,
        hiddenMacros: ["offers", "timer"],
        disabledNotifications: [],
        autoStartSession: false,
        sendEndSessionEmail: true,
        fullSizeVideosInRecording: true,
        passcodeToJoin: null,
        provideSessionPasscode: false,
        bypassSessionPasscode: false,
        groupChatAccess: true,
        privateChatAccess: PrivateChatAccess.Everyone,
        participantsMacroAccess: ParticipantsMacroAccess.Everyone,
        exitPageOfferId: "",
        collectFeedback: CollectFeedbackType.SessionsFeedback,
        showExitPageCta: ExitPageCtaType.SessionsCta,
        hideIcsGuestList: false,
        livestreamOutputUrls: {},
        recordingType: SessionRecordingType.Recording,
        feedbackFormId: "",
    },
    [SessionSettingsSource.Planned]: {
        allowMicrophoneType: SessionFlagsType.Everyone,
        allowScreenShareType: SessionFlagsType.Everyone,
        enableReactionsType: SessionFlagsType.Everyone,
        askForGuestEmail: false,
        requestPermissionToJoin: false,
        requireConsentToRecord: false,
        allowCameraType: SessionFlagsType.Everyone,
        hideNonStreamingParticipants: false,
        autoRecording: false,
        memoryAccessType: MemoryAccessType.JustAttendees,
        allowAgendaType: SessionFlagsType.Everyone,
        reminders: {},
        messageReminders: {},
        autoTranscribing: false,
        hiddenMacros: ["offers", "timer"],
        disabledNotifications: [],
        autoStartSession: false,
        sendEndSessionEmail: true,
        fullSizeVideosInRecording: true,
        passcodeToJoin: null,
        provideSessionPasscode: false,
        bypassSessionPasscode: false,
        groupChatAccess: true,
        privateChatAccess: PrivateChatAccess.Everyone,
        participantsMacroAccess: ParticipantsMacroAccess.Everyone,
        exitPageOfferId: "",
        collectFeedback: CollectFeedbackType.SessionsFeedback,
        showExitPageCta: ExitPageCtaType.SessionsCta,
        hideIcsGuestList: false,
        livestreamOutputUrls: {},
        recordingType: SessionRecordingType.Recording,
        feedbackFormId: "",
    },
    [SessionSettingsSource.Booking]: {
        allowMicrophoneType: SessionFlagsType.Everyone,
        allowScreenShareType: SessionFlagsType.Everyone,
        enableReactionsType: SessionFlagsType.Everyone,
        askForGuestEmail: false,
        requestPermissionToJoin: false,
        requireConsentToRecord: false,
        allowCameraType: SessionFlagsType.Everyone,
        hideNonStreamingParticipants: false,
        autoRecording: false,
        memoryAccessType: MemoryAccessType.JustAttendees,
        allowAgendaType: SessionFlagsType.Everyone,
        reminders: {
            reminders: [
                {
                    minutes: 60,
                    unit: "hours",
                },
            ],
        },
        messageReminders: {
            smsReminders: [],
        },
        autoTranscribing: false,
        hiddenMacros: ["offers", "timer"],
        disabledNotifications: [],
        autoStartSession: false,
        sendEndSessionEmail: false,
        fullSizeVideosInRecording: true,
        passcodeToJoin: null,
        provideSessionPasscode: false,
        bypassSessionPasscode: false,
        groupChatAccess: true,
        privateChatAccess: PrivateChatAccess.Everyone,
        participantsMacroAccess: ParticipantsMacroAccess.Everyone,
        exitPageOfferId: "",
        collectFeedback: CollectFeedbackType.SessionsFeedback,
        showExitPageCta: ExitPageCtaType.SessionsCta,
        hideIcsGuestList: false,
        livestreamOutputUrls: {},
        recordingType: SessionRecordingType.Recording,
        feedbackFormId: "",
    },
    [SessionSettingsSource.Event]: {
        allowMicrophoneType: SessionFlagsType.Speakers,
        allowScreenShareType: SessionFlagsType.Speakers,
        enableReactionsType: SessionFlagsType.Everyone,
        askForGuestEmail: false,
        requestPermissionToJoin: true,
        requireConsentToRecord: false,
        allowCameraType: SessionFlagsType.Speakers,
        hideNonStreamingParticipants: true,
        autoRecording: false,
        memoryAccessType: MemoryAccessType.JustAttendees,
        allowAgendaType: SessionFlagsType.Everyone,
        reminders: {
            reminders: [
                {
                    minutes: 60,
                    unit: "hours",
                },
            ],
        },
        messageReminders: {
            smsReminders: [],
        },
        autoTranscribing: false,
        hiddenMacros: ["offers", "timer"],
        disabledNotifications: [],
        autoStartSession: false,
        sendEndSessionEmail: true,
        fullSizeVideosInRecording: true,
        passcodeToJoin: null,
        provideSessionPasscode: false,
        bypassSessionPasscode: false,
        groupChatAccess: true,
        privateChatAccess: PrivateChatAccess.Everyone,
        participantsMacroAccess: ParticipantsMacroAccess.Moderators,
        exitPageOfferId: "",
        collectFeedback: CollectFeedbackType.SessionsFeedback,
        showExitPageCta: ExitPageCtaType.SessionsCta,
        hideIcsGuestList: false,
        livestreamOutputUrls: {},
        recordingType: SessionRecordingType.Recording,
        feedbackFormId: "",
    },
    [SessionSettingsSource.Room]: {
        allowMicrophoneType: SessionFlagsType.Everyone,
        allowScreenShareType: SessionFlagsType.Everyone,
        enableReactionsType: SessionFlagsType.Everyone,
        askForGuestEmail: false,
        requestPermissionToJoin: false,
        requireConsentToRecord: false,
        allowCameraType: SessionFlagsType.Everyone,
        hideNonStreamingParticipants: false,
        autoRecording: false,
        memoryAccessType: MemoryAccessType.JustAttendees,
        allowAgendaType: SessionFlagsType.Everyone,
        reminders: {
            reminders: [
                {
                    minutes: 60,
                    unit: "hours",
                },
            ],
        },
        messageReminders: {
            smsReminders: [],
        },
        autoTranscribing: false,
        hiddenMacros: ["offers", "timer"],
        disabledNotifications: [],
        autoStartSession: false,
        sendEndSessionEmail: false,
        fullSizeVideosInRecording: true,
        passcodeToJoin: null,
        provideSessionPasscode: false,
        bypassSessionPasscode: false,
        groupChatAccess: true,
        privateChatAccess: PrivateChatAccess.Everyone,
        participantsMacroAccess: ParticipantsMacroAccess.Everyone,
        exitPageOfferId: "",
        collectFeedback: CollectFeedbackType.SessionsFeedback,
        showExitPageCta: ExitPageCtaType.SessionsCta,
        hideIcsGuestList: false,
        livestreamOutputUrls: {},
        recordingType: SessionRecordingType.Recording,
        feedbackFormId: "",
    },
};

export const useInitialSessionFlags = (
    type: SessionSettingsSource,
    workspaceId: string
): Omit<SessionSettingsFragmentFragment, "id" | "sessionSource" | "__typename"> | null => {
    const {data, loading} = useQuery(GetSessionSettingsDocument, {
        variables: {
            workspaceId,
        },
    });
    if (loading) {
        return {
            ...initialSessionFlags[type],
            passcodeToJoin: initialSessionFlags[type].provideSessionPasscode ? generateSessionPasscode() : null,
        };
    }

    const sessionTemplate = data?.sessionSettings.find((template) => template.sessionSource === type);

    const {id, sessionSource, __typename, ...rest} = sessionTemplate ?? {};

    return {
        ...initialSessionFlags[type],
        ...rest,
        passcodeToJoin: sessionTemplate?.provideSessionPasscode ? generateSessionPasscode() : null,
    };
};

export const getInitialSessionFlags = async (
    type: SessionSettingsSource
): Promise<Omit<SessionSettingsFragmentFragment, "id" | "sessionSource">> => {
    const workspaceId = readRemoteUser()?.getRemoteUser.user?.workspacePermissions.find((p) => p.activeWorkspace)?.workspace.id ?? "";

    const {data} = await apollo.client.query({
        query: GetSessionSettingsDocument,
        variables: {
            workspaceId,
        },
    });

    const sessionTemplate = data.sessionSettings.find((template) => template.sessionSource === type);

    const {id, sessionSource, __typename, ...rest} = sessionTemplate ?? {};
    return {
        ...initialSessionFlags[type],
        ...rest,
        passcodeToJoin: sessionTemplate?.provideSessionPasscode ? generateSessionPasscode() : null,
    };
};
