import Typography from "@ui/cdk/Typography";
import designer from "@workhorse/api/designer";
import {useSession} from "@workhorse/providers/SessionDataProviders";
import WidgetGroupChatToggler from "@workhorse/api/session-settings/sections/SessionSection/components/Macros/WidgetGroupChatToggler";
import WidgetPollsToggler from "@workhorse/api/session-settings/sections/SessionSection/components/Macros/WidgetPollsToggler";
import WidgetQuestionsToggler from "@workhorse/api/session-settings/sections/SessionSection/components/Macros/WidgetQuestionsToggler";
import classes from "../eventCommons.module.scss";
import WidgetTakeawaysToggler from "@workhorse/api/session-settings/sections/SessionSection/components/Macros/WidgetTakeawaysToggler";
import WidgetNotesToggler from "@workhorse/api/session-settings/sections/SessionSection/components/Macros/WidgetNotesToggler";
import WidgetTranscriptToggler from "@workhorse/api/session-settings/sections/SessionSection/components/Macros/WidgetTranscriptToggler";
import HideAgendaSelector from "@workhorse/api/session-settings/sections/AccessSection/components/HideAgendaSelector";
import {useMutation} from "@workhorse/dataApi";
import {useDesignerSessionLocked} from "@workhorse/providers/DesignerSessionDataProviders";
import {SessionFlags, SessionLifecycle} from "@generated/data";
import {useMemo} from "@workhorse/api/rendering";
import SessionTemplateSettingsNotification from "@workhorse/api/session-settings/sections/SessionSection/components/SessionTemplateSettingsNotification";
import WidgetParticipantsSelector from "@workhorse/api/session-settings/sections/SessionSection/components/Macros/WidgetParticipantsSelector";
import WidgetOffersToggler from "@workhorse/api/session-settings/sections/SessionSection/components/Macros/WidgetOffersToggler";
import WidgetPrivateChatSelector from "@workhorse/api/session-settings/sections/SessionSection/components/Macros/WidgetPrivateChatSelector";
import WidgetTimerToggler from "@workhorse/api/session-settings/sections/SessionSection/components/Macros/WidgetTimerToggler";

type EventWidgetsProps = {
    sessionTemplateNotificationDismissed: boolean;
    setSessionTemplateNotificationDismissed: (value: boolean) => void;
};

export function EventWidgets(props: EventWidgetsProps) {
    const {sessionTemplateNotificationDismissed, setSessionTemplateNotificationDismissed} = props;

    const session = useSession();
    const [lockEvent] = useMutation("LockEventDocument");
    const isLocked = useDesignerSessionLocked();

    const eventNotUpdatable = useMemo(
        () => [SessionLifecycle.Started, SessionLifecycle.Ended].includes(session.lifecycle) || isLocked,
        [session.lifecycle, isLocked]
    );
    const handleChangeMacro = (
        macroArtifactId: "flowos/polls" | "flowos/qa" | "flowos/takeaways" | "flowos/transcript" | "flowos/contextual-notes"
    ) => {
        lockEvent({
            variables: {
                id: session.id,
            },
        });
        designer.api.session.update({
            hiddenMacros: session.hiddenMacros.some((x) => x === macroArtifactId)
                ? session.hiddenMacros.filter((x) => x !== macroArtifactId)
                : session.hiddenMacros.concat([macroArtifactId as string]),
        });

        designer.commit();
    };

    const handleChangeValue = <TKey extends keyof SessionFlags>(key: TKey, value: SessionFlags[TKey]) => {
        lockEvent({
            variables: {
                id: session.id,
            },
        });

        designer.api.session.update({
            [key]: value,
        });

        designer.commit();
    };

    const isPollsHidden = session.hiddenMacros?.some((x) => x === "flowos/polls");
    const isQAHidden = session.hiddenMacros?.some((x) => x === "flowos/qa");
    const isTakeawaysHidden = session.hiddenMacros?.some((x) => x === "flowos/takeaways");
    const isNotesHidden = session?.hiddenMacros?.some((x) => x === "flowos/contextual-notes");
    const isTranscriptHidden = session?.hiddenMacros?.some((x) => x === "flowos/transcript");
    const isOffersHidden = session?.hiddenMacros?.some((x) => x === "offers");
    const isTimerHidden = session?.hiddenMacros?.some((x) => x === "timer");

    return (
        <div className={classes.mainContainer}>
            <div className="flex flex-align-center flex-justify-between">
                <Typography fontWeight="bolder" variant="xl3" color="secondary" className="mb-24">
                    Widgets
                </Typography>
            </div>

            <HideAgendaSelector
                value={session.allowAgendaType}
                onChange={handleChangeValue.bind(null, "allowAgendaType")}
                showIcon={true}
                disabled={eventNotUpdatable}
            />

            <div className={classes.separator} />

            <WidgetParticipantsSelector
                value={session.participantsMacroAccess}
                onChange={handleChangeValue.bind(null, "participantsMacroAccess")}
                showIcon={true}
                disabled={eventNotUpdatable}
            />

            <div className={classes.separator} />

            <WidgetPrivateChatSelector
                value={session.privateChatAccess}
                onChange={handleChangeValue.bind(null, "privateChatAccess")}
                showIcon={true}
            />

            <div className={classes.separator} />

            <WidgetGroupChatToggler
                enabled={session.groupChatAccess}
                onChange={handleChangeValue.bind(null, "groupChatAccess")}
                showIcon={true}
                disabled={eventNotUpdatable}
            />

            <div className={classes.separator} />

            <WidgetPollsToggler enabled={!isPollsHidden} onChange={handleChangeMacro} showIcon={true} disabled={eventNotUpdatable} />

            <div className={classes.separator} />

            <WidgetQuestionsToggler enabled={!isQAHidden} onChange={handleChangeMacro} showIcon={true} disabled={eventNotUpdatable} />

            <div className={classes.separator} />

            <WidgetTakeawaysToggler
                enabled={!isTakeawaysHidden}
                onChange={handleChangeMacro}
                showIcon={true}
                disabled={eventNotUpdatable}
            />

            <div className={classes.separator} />

            <WidgetNotesToggler enabled={!isNotesHidden} onChange={handleChangeMacro} showIcon={true} disabled={eventNotUpdatable} />

            <div className={classes.separator} />

            <WidgetTranscriptToggler
                enabled={!isTranscriptHidden}
                onChange={handleChangeMacro}
                showIcon={true}
                disabled={eventNotUpdatable}
            />

            <div className={classes.separator} />

            <WidgetOffersToggler enabled={!isOffersHidden} onChange={handleChangeMacro} showIcon={true} disabled={eventNotUpdatable} />

            <div className={classes.separator} />

            <WidgetTimerToggler enabled={!isTimerHidden} onChange={handleChangeMacro} showIcon={true} disabled={eventNotUpdatable} />

            <SessionTemplateSettingsNotification
                type="event"
                isDismissed={sessionTemplateNotificationDismissed}
                setIsDismissed={setSessionTemplateNotificationDismissed}
                className={classes.sessionTemplateNotification}
            />
        </div>
    );
}
