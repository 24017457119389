import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import Button from "@ui/cdk/Button";
import Dialog, {DialogImperativeRef} from "@ui/cdk/Dialog/Dialog";
import DialogFooter from "@ui/cdk/Dialog/DialogFooter";
import Typography from "@ui/cdk/Typography";
import {cls} from "@ui/cdk/util";
import {useCoolState} from "@workhorse/api/state";
import Loading from "@workhorse/components/Loading";
import {useMobile} from "@workhorse/providers/MobileProvider";
import {useSession} from "@workhorse/providers/SessionDataProviders";
import {useUserInfo} from "@workhorse/providers/User";
import DialogColumn from "../dialog/DialogColumn";
import DialogGrid from "../dialog/DialogGrid";
import {useCallback, useEffect, useMemo, useRef} from "@workhorse/api/rendering";
import {SessionRole} from "@workhorse/api/user";
import {SessionSettingsGroupItem} from "./components/SessionSettingsGroupItem";
import {
    SessionSettingsGlobalState,
    SessionSettingsGroup,
    SessionSettingsGroupMap,
    SessionSettingsConfiguration,
    SessionSettingsSection,
    SessionSettingsSectionState,
    SessionSettingsSelection,
} from "./definitions";
import configuration, {sectionList} from "./configuration";
import {useSessionSettingsDialog} from "./providers";
import Sections from "./sections";
import styles from "./styles/SessionSettingsDialog.module.scss";
import {useDeviceOrientation} from "@workhorse/providers/DeviceOrientationProvider";
import {useTranslation} from "react-i18next";
import updateSessionFlags from "./utils/updateSessionFlags";
import {useParticipantsStore} from "@workhorse/api/conference2/providers/ParticipantsProvider/LocalParticipantsStore";
import {useShallow} from "zustand/react/shallow";
import {useBreakpoint} from "@workhorse/providers/BreakpointProvider";

type InnerState = {
    // All loaded Sections
    sections: SessionSettingsSection[];

    // The currently selected section index
    selectedIndex?: number;

    // The currently selected section
    selectedSection?: SessionSettingsSelection;

    // Set to true when submitting
    submitting: boolean;

    // Counts all the touched sections that have finished submitting
    submittingDone: Partial<Record<SessionSettingsSelection, boolean>>;

    // List of all touched sections
    touched: Record<SessionSettingsSelection, boolean>;

    // List of all errors from all sections
    errors: Record<SessionSettingsSelection, string | undefined>;
};

function count(obj: Record<string, boolean>) {
    return Object.values(obj).reduce((acc, t) => acc + (t ? 1 : 0), 0);
}

const initialState: InnerState = {
    sections: [],
    submitting: false,
    submittingDone: {},
    touched: Object.keys(sectionList).reduce((acc, key) => ({...acc, [key]: false}), {} as InnerState["touched"]),
    errors: sectionList.reduce((acc, {kind}) => ({...acc, [kind]: undefined}), {} as InnerState["errors"]),
};

export const SessionSettingsDialog = () => {
    const {t} = useTranslation();
    const {isTablet, isMobileOrTablet: isMobileOrTabletFromHook} = useMobile();

    const breakpoint = useBreakpoint();
    const isMobileOrTabletSize = ["xs", "sm", "md"].indexOf(breakpoint) !== -1;

    const {role, isGuest} = useUserInfo();

    const {isOwner, isAssistant} = useParticipantsStore(
        useShallow(({currentParticipant, amIAssistant}) => ({
            isOwner: currentParticipant?.isOwner,
            isAssistant: amIAssistant,
        }))
    );

    const isUser = role === "USER";
    const session = useSession();
    const [state, setState, getState] = useCoolState<InnerState>({...initialState});
    const {
        store: {open, section, group},
        show: setDialog,
        hide: discardDialog,
    } = useSessionSettingsDialog();
    const dialogImperativeRef = useRef<DialogImperativeRef>({});

    const [globalState, setGlobalState] = useCoolState<SessionSettingsGlobalState>({});

    const filterByRbacSettings = (rbac: Record<SessionRole | "isOwner" | "isUser" | "isGuest", boolean> | "none" | undefined): boolean => {
        if (!rbac) {
            return true;
        }

        if (rbac === "none") {
            return true;
        }

        if (rbac.isGuest && isGuest) {
            return true;
        }

        if (rbac.isUser && isUser) {
            return true;
        }

        if (rbac["session.isAssistant"] && isAssistant) {
            return true;
        }

        if (rbac.isOwner && isOwner) {
            return true;
        }

        return false;
    };

    const filterBySessionType = (
        session: {isRoom: boolean; isBooking: boolean; isEvent: boolean; isPlanned: boolean},
        kind: SessionSettingsSelection
    ) => {
        const {isRoom, isBooking, isEvent, isPlanned} = session;

        if (isRoom || isBooking) {
            return kind !== "general.livestreaming";
        }

        return true;
    };

    const filteredGroups = useMemo(
        () =>
            Object.entries(configuration)
                .filter(([_, group]: [string, SessionSettingsGroup]) => {
                    return filterByRbacSettings(group.rbac);
                })

                .reduce(
                    (acc, [name, {sections, ...group}]) => ({
                        ...acc,
                        [name]: {
                            ...group,
                            sections: Object.entries(sections)
                                .filter(([_, {show, rbac, showIfStarted}]: [string, SessionSettingsSection]) => {
                                    if (showIfStarted !== undefined) {
                                        return showIfStarted(session?.lifecycle);
                                    }
                                    if (show !== undefined) {
                                        return show;
                                    }
                                    return filterByRbacSettings(rbac);
                                })
                                .filter(([_, {kind}]: [string, SessionSettingsSection]) => {
                                    return filterBySessionType(
                                        {
                                            isRoom: !!session?.room?.id,
                                            isBooking: session?.isBooking,
                                            isEvent: !!session?.event?.id,
                                            isPlanned: !session?.quickSession,
                                        },
                                        kind
                                    );
                                })
                                .reduce(
                                    (acc, [name, def]) => ({
                                        ...acc,
                                        [name]: def,
                                    }),
                                    {}
                                ),
                        },
                    }),
                    {} as SessionSettingsConfiguration
                ),
        [isUser, isGuest, isOwner, isAssistant, session?.lifecycle]
    );

    const availableGroups = useMemo(() => Object.keys(filteredGroups), [filteredGroups]);

    const hideDialog = useCallback(() => {
        discardDialog();
        setGlobalState({});
        setState({...initialState});
    }, [discardDialog, setGlobalState, setState]);

    const handleGoBack = useCallback(() => {
        setDialog(group!);
        setState((s) => ({...s, selectedIndex: undefined, section: undefined}));
    }, [group, setDialog]);

    useEffect(() => {
        if ((open && !dialogImperativeRef.current?.isOpen) || (!open && dialogImperativeRef.current?.isOpen)) {
            dialogImperativeRef.current?.toggle?.();
        }

        if (!group || !section) {
            return;
        }

        if (open) {
            const savedState = getState();
            const loadedSections = [...savedState.sections];
            const newSection = sectionList.find(({kind}) => kind === section);

            if (newSection && !loadedSections.find(({kind}) => kind === section)) {
                loadedSections.push(newSection);
            }

            const selectedIndex = loadedSections.findIndex(({kind}) => kind === section);

            setState({...savedState, selectedIndex, selectedSection: section, sections: loadedSections});
        } else {
            setState({...initialState});
        }
    }, [getState, open, group, section, setState]);

    useEffect(() => {
        const touched = count(state.touched);
        const submitted = count(state.submittingDone);

        if (state.submitting && submitted === touched) {
            // we hide the dialog when all the sections have finished submitting
            hideDialog();
        } else if (state.submitting) {
            // all sections have a 100ms time frame for submitting
            // after that, the dialog will close
            const timeout = setTimeout(() => {
                hideDialog();
            }, 100);

            return () => {
                clearTimeout(timeout);
            };
        }
    }, [state.submitting, state.submittingDone, state.touched, hideDialog]);

    const {orientation} = useDeviceOrientation();

    const isTabletAndLandscape = isTablet && orientation === "landscape";
    const isMobileOrTablet = isMobileOrTabletFromHook && !isTabletAndLandscape && isMobileOrTabletSize;

    useEffect(() => {
        if (group && !availableGroups.includes(group)) {
            setDialog(isMobileOrTablet ? "device" : "device.video");

            setState((currentState) => {
                const sections = currentState.sections.filter(({kind}) => {
                    const group = kind.split(".")[0] as SessionSettingsGroupMap;

                    return availableGroups.includes(group);
                });

                const selectedIndex = isMobileOrTablet ? undefined : sections.findIndex(({kind}) => kind === "device.video");
                const selectedSection = isMobileOrTablet ? undefined : "device.video";

                return {
                    ...currentState,
                    sections,
                    selectedIndex,
                    selectedSection,
                };
            });
        }
    }, [availableGroups, isMobileOrTablet]);

    useEffect(() => {
        hideDialog();
    }, [orientation]);

    const isTranscriptHidden = session?.hiddenMacros?.some((x) => x === "flowos/transcript");
    const isAutoTranscribing = session.autoTranscribing;

    useEffect(() => {
        if (isTranscriptHidden && isAutoTranscribing) {
            updateSessionFlags(session.id, {autoTranscribing: false});
        }
    }, [isTranscriptHidden, session.id, isAutoTranscribing]);

    const handleOnSubmit = useCallback(() => {
        setState((s) => ({...s, submitting: true}));
    }, [setState]);

    const handleOnSectionSubmitDone = useCallback(async (kind: SessionSettingsSelection) => {
        const savedState = getState();

        if (savedState.touched[kind]) {
            setState((s) => ({
                ...s,
                touched: {
                    ...s.touched,
                    [kind]: false,
                },
                submittingDone: {...s.submittingDone, [kind]: true},
            }));
        }
    }, []);

    const handleOnChange = useCallback(
        <TKey extends "touched" | "errors">(
            key: TKey,
            kind: SessionSettingsSelection,
            value: TKey extends "touched" ? boolean : string
        ) => {
            setState((s) => ({
                ...s,
                [key]: {
                    ...s[key],
                    [kind]: value,
                },
            }));
        },
        []
    );

    const handleOnGlobalStateChange = useCallback(
        <TSection extends SessionSettingsSelection>(kind: TSection, newValue: SessionSettingsSectionState<TSection>) => {
            setGlobalState((s) => ({...s, [kind]: newValue}));
        },
        [setGlobalState]
    );

    const handleOnChangeTouch = useMemo(() => handleOnChange.bind(null, "touched"), [handleOnChange]);
    const handleOnChangeValidate = useMemo(() => handleOnChange.bind(null, "errors"), [handleOnChange]);

    const sectionUnavailable = useMemo(() => group && !availableGroups.includes(group), [group, availableGroups]);

    const notice = useMemo(
        () =>
            sectionUnavailable
                ? ""
                : state.selectedIndex !== undefined && state.sections[state.selectedIndex].notice
                ? state.sections[state.selectedIndex].notice
                : group && filteredGroups[group].notice
                ? filteredGroups[group].notice
                : undefined,
        [group, filteredGroups, state, sectionUnavailable]
    );

    const noticeTranslateKey = useMemo(
        () =>
            sectionUnavailable
                ? ""
                : state.selectedIndex !== undefined && state.sections[state.selectedIndex].noticeTranslateKey
                ? state.sections[state.selectedIndex].noticeTranslateKey
                : group && filteredGroups[group].noticeTranslateKey
                ? filteredGroups[group].noticeTranslateKey
                : undefined,
        [group, filteredGroups, state, sectionUnavailable]
    );

    const sectionTitle = useMemo(
        () =>
            sectionUnavailable
                ? ""
                : state.selectedIndex !== undefined &&
                  (t(`player.settings.${state.sections[state.selectedIndex].translateKey}.title`) ??
                      state.sections[state.selectedIndex].title),
        [sectionUnavailable, state.selectedIndex, state.sections, t]
    );
    const hasTouched = useMemo(() => Object.values(state.touched).reduce((acc, tc) => acc || tc, false), [state.touched]);
    const hasErrors = useMemo(() => Object.values(state.errors).reduce((acc, err) => acc || !!err, false), [state.errors]);

    const menu = useMemo(
        () => (
            <div className={styles.sectionWrapper}>
                <div className={styles.sectionContent}>
                    <Typography variant="xl" fontWeight="bolder" color="duodenary" className={styles.sectionTitle}>
                        {t("player.settings.title")}
                    </Typography>

                    <div className={styles.sectionMenu} role="list">
                        {Object.entries(filteredGroups).map(([groupName, group]) => (
                            <SessionSettingsGroupItem
                                key={groupName}
                                errors={state.errors}
                                touched={state.touched}
                                onSelectSection={setDialog}
                                selected={state.selectedSection}
                                {...group}
                                isAdmin={isOwner}
                                isAssistant={isAssistant}
                            />
                        ))}
                    </div>
                </div>

                {(notice || noticeTranslateKey) && (
                    <Typography color="blueGray400" component="div" className={styles.sectionNotice}>
                        <InfoOutlinedIcon />
                        <div>{t(`player.settings.${noticeTranslateKey}`) ?? notice}</div>
                    </Typography>
                )}
            </div>
        ),
        [filteredGroups, notice, noticeTranslateKey, state.errors, state.touched, state.selectedSection, t]
    );

    const footer = useMemo(
        () => (
            <>
                {isMobileOrTablet && section && (
                    <Button variant="tertiary" onClick={handleGoBack}>
                        {t("g.back")}
                    </Button>
                )}

                {hasTouched && (
                    <>
                        <Button disabled={state.submitting} variant="quaternary" onClick={hideDialog}>
                            {t("g.cancel")}
                        </Button>
                        <Button disabled={hasErrors || state.submitting} onClick={handleOnSubmit}>
                            {t("g.confirm")}
                        </Button>
                    </>
                )}
            </>
        ),
        [isMobileOrTablet, section, hasTouched, hasErrors, state.submitting, handleOnSubmit, hideDialog, t]
    );

    const handleGoToRecordingSection = useCallback(() => {
        setDialog("general.recording");
    }, [setDialog]);

    const sections = useMemo(
        () =>
            open && (
                <Sections
                    sections={state.sections}
                    selected={state.selectedIndex}
                    submitting={state.submitting}
                    onSubmitDone={handleOnSectionSubmitDone}
                    onTouch={handleOnChangeTouch}
                    onValidate={handleOnChangeValidate}
                    onGlobalStateChange={handleOnGlobalStateChange}
                    submittingDone={state.submittingDone}
                    touched={state.touched}
                    errors={state.errors}
                    globalState={globalState}
                    onGoToRecordingSection={handleGoToRecordingSection}
                />
            ),
        [state]
    );

    return (
        <Dialog
            imperativeRef={dialogImperativeRef}
            onClose={hideDialog}
            classes={{
                paper: styles.paper,
            }}
            PaperProps={{
                "aria-label": t("player.settings.aria.session_settings") ?? "Session settings",
            }}
            disableBackdropClick
            showCloseBtn
        >
            {sectionUnavailable ? (
                <Loading location="SessionSettingsDialog.tsx" />
            ) : (
                <>
                    <DialogGrid>
                        {isMobileOrTablet && !section && menu}

                        {!isMobileOrTablet && (
                            <DialogColumn left className={styles.navbar}>
                                {menu}
                            </DialogColumn>
                        )}

                        {isMobileOrTablet ? (
                            <div className={cls(styles.sections, state.selectedIndex !== undefined && styles.sectionsShow)}>
                                <Typography variant="xl3" fontWeight="boldest" color="duodenary" className={styles.sectionTitle}>
                                    {sectionTitle}
                                </Typography>
                                {sections}
                            </div>
                        ) : (
                            <DialogColumn right className={styles.content}>
                                {sections}
                            </DialogColumn>
                        )}
                    </DialogGrid>

                    {isMobileOrTablet
                        ? (section || hasTouched) && <div className={styles.footer}>{footer}</div>
                        : hasTouched && <DialogFooter className={styles.footer}>{footer}</DialogFooter>}
                </>
            )}
        </Dialog>
    );
};
